<template>
  <section class="top-nav-section">
    <div class="top-nav-wrap">
      <div class="container">
        <div class="top-nav">

          <div class="top-nav__left">
            <span class="unread_number" @click="openLeftNavbar" v-if="unread_messages_count > 0">{{ unread_messages_count
            }}</span>
            <div id="burger" class="top-nav__burger" @click="openLeftNavbar" :class="open_left_navbar ? 'active' : ''">
              <span></span>
            </div>

            <div class="top-nav__search">
              <svg @click="showSearchTrigger" :class="search_show ? 'off': ''" id="search-btn" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.40606 1.25C7.38603 1.24989 8.34574 1.52905 9.17277 2.05477C9.99979 2.58049 10.6599 3.331 11.0757 4.21838C11.4915 5.10576 11.6458 6.09326 11.5206 7.0652C11.3954 8.03714 10.9957 8.95326 10.3686 9.70625L13.9248 13.2625C13.9865 13.3198 14.0316 13.3927 14.0554 13.4735C14.0791 13.5542 14.0807 13.6399 14.0598 13.7215C14.039 13.8031 13.9965 13.8775 13.9369 13.937C13.8772 13.9964 13.8027 14.0387 13.7211 14.0594C13.6396 14.0803 13.554 14.0788 13.4732 14.0552C13.3925 14.0315 13.3196 13.9865 13.2623 13.925L9.70606 10.3687C9.0697 10.8986 8.3151 11.2674 7.50607 11.4439C6.69705 11.6205 5.8574 11.5996 5.05816 11.383C4.25892 11.1664 3.52359 10.7606 2.91438 10.1997C2.30517 9.63886 1.84 8.93953 1.55822 8.16089C1.27643 7.38224 1.18632 6.54719 1.29551 5.72635C1.4047 4.90552 1.70997 4.12305 2.18551 3.44515C2.66105 2.76724 3.29286 2.21384 4.0275 1.83174C4.76214 1.44965 5.57799 1.25011 6.40606 1.25ZM2.18731 6.40625C2.18731 6.96026 2.29643 7.50885 2.50844 8.0207C2.72045 8.53254 3.0312 8.99761 3.42295 9.38936C3.8147 9.7811 4.27977 10.0919 4.79161 10.3039C5.30345 10.5159 5.85204 10.625 6.40606 10.625C6.96007 10.625 7.50866 10.5159 8.0205 10.3039C8.53235 10.0919 8.99742 9.7811 9.38917 9.38936C9.78091 8.99761 10.0917 8.53254 10.3037 8.0207C10.5157 7.50885 10.6248 6.96026 10.6248 6.40625C10.6248 5.28737 10.1803 4.21431 9.38917 3.42314C8.598 2.63197 7.52494 2.1875 6.40606 2.1875C5.28718 2.1875 4.21412 2.63197 3.42295 3.42314C2.63178 4.21431 2.18731 5.28737 2.18731 6.40625Z" fill="#0077FF"/>
              </svg>
              <div id="inp" class="inp" :class="search_show ? 'active' : ''">
                <input type="text" @blur="blurSearch" @input="search" placeholder="Search">
                <!-- <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.40606 1.25C7.38603 1.24989 8.34574 1.52905 9.17277 2.05477C9.99979 2.58049 10.6599 3.331 11.0757 4.21838C11.4915 5.10576 11.6458 6.09326 11.5206 7.0652C11.3954 8.03714 10.9957 8.95326 10.3686 9.70625L13.9248 13.2625C13.9865 13.3198 14.0316 13.3927 14.0554 13.4735C14.0791 13.5542 14.0807 13.6399 14.0598 13.7215C14.039 13.8031 13.9965 13.8775 13.9369 13.937C13.8772 13.9964 13.8027 14.0387 13.7211 14.0594C13.6396 14.0803 13.554 14.0788 13.4732 14.0552C13.3925 14.0315 13.3196 13.9865 13.2623 13.925L9.70606 10.3687C9.0697 10.8986 8.3151 11.2674 7.50607 11.4439C6.69705 11.6205 5.8574 11.5996 5.05816 11.383C4.25892 11.1664 3.52359 10.7606 2.91438 10.1997C2.30517 9.63886 1.84 8.93953 1.55822 8.16089C1.27643 7.38224 1.18632 6.54719 1.29551 5.72635C1.4047 4.90552 1.70997 4.12305 2.18551 3.44515C2.66105 2.76724 3.29286 2.21384 4.0275 1.83174C4.76214 1.44965 5.57799 1.25011 6.40606 1.25ZM2.18731 6.40625C2.18731 6.96026 2.29643 7.50885 2.50844 8.0207C2.72045 8.53254 3.0312 8.99761 3.42295 9.38936C3.8147 9.7811 4.27977 10.0919 4.79161 10.3039C5.30345 10.5159 5.85204 10.625 6.40606 10.625C6.96007 10.625 7.50866 10.5159 8.0205 10.3039C8.53235 10.0919 8.99742 9.7811 9.38917 9.38936C9.78091 8.99761 10.0917 8.53254 10.3037 8.0207C10.5157 7.50885 10.6248 6.96026 10.6248 6.40625C10.6248 5.28737 10.1803 4.21431 9.38917 3.42314C8.598 2.63197 7.52494 2.1875 6.40606 2.1875C5.28718 2.1875 4.21412 2.63197 3.42295 3.42314C2.63178 4.21431 2.18731 5.28737 2.18731 6.40625Z" fill="#0077FF"/>
                </svg> -->
                <i class="el-icon-close" @click="showSearchTrigger"></i>
              </div>
            </div>
          </div>

          <div class="top-nav__right">

            <div id="nav-bell-btn" class="top-nav__bell" @click="showActivityLog">
              <div class="has_log" v-if="user.activity_log_new"></div>
              <svg width="20" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path 
                  d="M18.822 16.646a9.197 9.197 0 0 1-1.604-1.872 8.068 8.068 0 0 1-.862-3.073V8.545a6.906 6.906 0 0 0-6.032-6.868V.853a.853.853 0 1 0-1.705 0v.837a6.906 6.906 0 0 0-5.961 6.855v3.156a8.07 8.07 0 0 1-.863 3.073 9.197 9.197 0 0 1-1.578 1.872.64.64 0 0 0-.217.48v.868a.639.639 0 0 0 .639.64H18.4a.64.64 0 0 0 .639-.64v-.869a.64.64 0 0 0-.217-.479Zm-17.493.71a10.33 10.33 0 0 0 1.559-1.917A9.098 9.098 0 0 0 3.942 11.7V8.545a5.584 5.584 0 1 1 11.161 0v3.156a9.098 9.098 0 0 0 1.054 3.738c.442.699.965 1.342 1.56 1.916H1.328Zm8.223 3.411a1.706 1.706 0 0 0 1.648-1.456H7.84a1.706 1.706 0 0 0 1.712 1.456Z" 
                  fill="#0077FF"
                />
              </svg>
            </div>

            <div class="top-nav__user">
              <!-- v-html="$w_user_image(user)" -->
              <span @click.stop="openAvatarMenu">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_7_953)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.4856 18.4276C10.5926 17.8276 13.4204 17.8792 17.5328 18.4486C17.8306 18.4918 18.1028 18.6413 18.2989 18.8694C18.4951 19.0976 18.6021 19.3891 18.6002 19.69C18.6002 19.978 18.5012 20.2576 18.3224 20.476C18.0108 20.8568 17.6915 21.2313 17.3648 21.5992H18.9494C19.049 21.4804 19.1492 21.3592 19.2506 21.2362C19.6065 20.7998 19.8006 20.2538 19.8002 19.6906C19.8002 18.4756 18.9134 17.4286 17.6972 17.2606C13.4876 16.678 10.5452 16.6222 6.3122 17.2408C5.0834 17.4202 4.2002 18.4834 4.2002 19.7068C4.2002 20.2498 4.3772 20.7868 4.7126 21.2218C4.8116 21.3502 4.9094 21.4762 5.0066 21.5998H6.5528C6.24885 21.2359 5.95239 20.8659 5.6636 20.4898C5.49197 20.2647 5.39939 19.9893 5.4002 19.7062C5.4002 19.06 5.8646 18.5182 6.4856 18.4276ZM12.0002 12.5992C12.473 12.5992 12.9411 12.5061 13.3779 12.3252C13.8146 12.1443 14.2115 11.8791 14.5458 11.5448C14.8801 11.2105 15.1452 10.8137 15.3262 10.3769C15.5071 9.94011 15.6002 9.47198 15.6002 8.99922C15.6002 8.52646 15.5071 8.05833 15.3262 7.62156C15.1452 7.18479 14.8801 6.78793 14.5458 6.45363C14.2115 6.11934 13.8146 5.85417 13.3779 5.67325C12.9411 5.49234 12.473 5.39922 12.0002 5.39922C11.0454 5.39922 10.1297 5.7785 9.45461 6.45363C8.77948 7.12877 8.4002 8.04444 8.4002 8.99922C8.4002 9.954 8.77948 10.8697 9.45461 11.5448C10.1297 12.2199 11.0454 12.5992 12.0002 12.5992ZM12.0002 13.7992C13.2732 13.7992 14.4941 13.2935 15.3943 12.3933C16.2945 11.4932 16.8002 10.2723 16.8002 8.99922C16.8002 7.72618 16.2945 6.50528 15.3943 5.60511C14.4941 4.70493 13.2732 4.19922 12.0002 4.19922C10.7272 4.19922 9.50626 4.70493 8.60608 5.60511C7.70591 6.50528 7.2002 7.72618 7.2002 8.99922C7.2002 10.2723 7.70591 11.4932 8.60608 12.3933C9.50626 13.2935 10.7272 13.7992 12.0002 13.7992Z" fill="#0077FF"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.8C17.9646 22.8 22.8 17.9646 22.8 12C22.8 6.0354 17.9646 1.2 12 1.2C6.0354 1.2 1.2 6.0354 1.2 12C1.2 17.9646 6.0354 22.8 12 22.8ZM12 24C18.6276 24 24 18.6276 24 12C24 5.3724 18.6276 0 12 0C5.3724 0 0 5.3724 0 12C0 18.6276 5.3724 24 12 24Z" fill="#0077FF"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_7_953">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <div v-if="menuAccount" class="top-nav__user-menu">
                <router-link to="/setting/" class="top">
                  <span :style="'background-color: ' + user.color" v-html="$w_user_image(user)"></span>
                  <div class="box">
                    <h6>{{ user.name }}</h6>
                    <p>{{ user.email }}</p>
                  </div>
                </router-link>
                <router-link to="/setting/" class="menu">Settings</router-link>
                <router-link to="/setting?tab=theme" class="menu">Theme</router-link>
                <p class="menu" @click="logout">Logout</p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </section>
</template>
  
<script>

import {mapGetters, mapMutations, mapActions} from "vuex"
import { Badge } from '@capawesome/capacitor-badge'
import { FCM } from '@capacitor-community/fcm'
document.head.querySelector("[name~=theme-color][content]").content = '#c78495'

export default {
  data: () => ({
    open_left_navbar: false,
    search_show: false,
    menuAccount: false,
    search_value: ''
  }),
  watch: {
    $route() {
      document.querySelector('.content').classList.remove('filter-blur--avatar-menu')
      this.open_left_navbar = false
    }
  },
  computed: {
    user() {
      return this.$store.state.key.user
    },
    lists() {
      return this.$store.state.projects.projects
    },
    chats() {
      return this.$store.state.chats.chats
    },
    unread_messages_count() {
      this.changeBadgeCount(this.unread_chats + this.unread_lists);
      return this.unread_chats + this.unread_lists;
    },
    unread_lists() {
      let num = 0
      this.lists.forEach(list => {
        if (this.$store.state.comments.unreaded_messages_in_project[list._id]) {
          num = num + Number(this.$store.state.comments.unreaded_messages_in_project[list._id])
        }
      })
      return num
    },
    unread_chats() {
      let num = 0
      this.chats.forEach(chat => {
        num = num + Number(chat.users.find(e => e.user_id == this.user._id).unread_number)
      })
      return num
    }
  },
  methods: {
    ...mapActions(['fetch_request']),
    openAvatarMenu() {
      this.menuAccount = !this.menuAccount
      if (this.menuAccount) {
        document.querySelector('.content').classList.add('filter-blur--avatar-menu')
      } else {
        document.querySelector('.content').classList.remove('filter-blur--avatar-menu')
      }
    },
    openLeftNavbar() {
      this.open_left_navbar = !this.open_left_navbar
      this.$emit("openLeftNavbar", this.open_left_navbar)
    },
    showSearchTrigger() {
      this.search_show = !this.search_show
      document.querySelector('.top-nav__search input').value = ''
      document.querySelector('.top-nav__search input').focus()
    },
    showActivityLog() {
      this.$socket.emit('IN_HistoryRead', true)
      this.$emit('showActivityLog')
    },
    blurSearch() {
      if (!this.search_value) {
        this.search_show = !this.search_show
      }
    },
    search($event) {
      this.search_value = $event.target.value
      this.open_left_navbar = false
      this.$emit("openLeftNavbar", false)
      this.$emit('search', $event.target.value)
    },
    async changeBadgeCount(count) {
      await Badge.set({ count });
    },
    async logout() {

      document.querySelector('.content').classList.remove('filter-blur--avatar-menu')

      let firebaseToken = ''

      try {
        const {token} = await FCM.getToken()
        firebaseToken = token
      } catch (error) {
        console.log(error.message)
      }
      
      const request = await this.fetch_request({
        token: true,
        method: "POST",
        url: this.$w_api.LOGOUT,
        body: {
          token: firebaseToken
        },
      })

      if (request.ok) {
        const data = await request.json()
        localStorage.removeItem("w_token")
        localStorage.removeItem("w_clone_token")
        window.location.reload()
      }
    }
  },
  mounted() {
    document.querySelector('.content__bg').onclick = (e) => {
      e.stopPropagation()
      if (this.menuAccount) {
        document.querySelector('.content').classList.remove('filter-blur--avatar-menu')
        this.menuAccount = false
      }
    }
    this.$root.$on('closeLeftBar', () => {
      this.open_left_navbar = false
    })
  }
}

</script>