<template>
  <div class="task-menu">
    <div class="task-menu_wrapper">
      <div class="task-menu__content menu1">
        <ul class="task-menu__lists">
          <li v-for="option of options" :key="option.type" @click="menuClick(option)" @touchmove="onTouchMove"
            @touchstart="onTouchStart">
            <span>{{ option.menu }}</span>
            <i v-if="option.children" class="el-icon-arrow-right"></i>
          </li>
        </ul>
        <ul class="task-menu__lists">
          <li v-for="option of options2" :key="option.type" @click="menuClick(option)">
            <span>{{ option.menu }}</span>
            <i v-if="option.children" class="el-icon-arrow-right"></i>
          </li>
        </ul>
        <ul class="task-menu__lists">
          <li v-for="option of options3" :key="option.type" @click="menuClick(option)">
            <span>{{ option.menu }}</span>
            <i v-if="option.children" class="el-icon-arrow-right"></i>
          </li>
        </ul>
      </div>
      <button class="task-menu__button" @click="closeOrBack">Close</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"

export default {
  props: ['bool'],
  data: () => ({
    task: '',
    type: '',
    isDisableClick: false,
    isMoved: false,
    options: [
      {
        menu: 'Add subtask',
        type: 'add_subtask',
        children: false,
        hidden: false
      },
      {
        menu: 'Rename task',
        type: 'rename',
        children: false,
        hidden: false
      },
      {
        menu: 'Priority',
        type: 'priority',
        children: true,
        hidden: false
      },
      {
        menu: 'Copy link to task',
        type: 'copy_link',
        children: false,
        hidden: false
      },
      {
        menu: 'Move to',
        type: 'move',
        children: true,
        hidden: false
      },
      {
        menu: 'Delete task',
        type: 'delete',
        children: false,
        hidden: false
      },
    ],
    options2: [],
    options3: []
  }),
  mounted() {
    this.$root.$on('task_menu', ({ task_id }) => {
      if (!this.$route.params.shortcuts && !this.bool) {
        let task = this.$store.state.tasks.tasks[this.$route.params.project_id] ? this.$store.state.tasks.tasks[this.$route.params.project_id].find(e => e._id == task_id) : []
        let complete_task = this.$store.state.tasks.completed_tasks[this.$route.params.project_id] ? this.$store.state.tasks.completed_tasks[this.$route.params.project_id].find(e => e._id == task_id) : []
        this.task = task || complete_task
        this.options = [
          {
            menu: 'Add subtask',
            type: 'add_subtask',
            children: false,
            hidden: false
          },
          {
            menu: 'Rename task',
            type: 'rename',
            children: false,
            hidden: false
          },
          {
            menu: 'Priority',
            type: 'priority',
            children: true,
            hidden: false
          },
          {
            menu: 'Copy link to task',
            type: 'copy_link',
            children: false,
            hidden: false
          },
          {
            menu: 'Move to',
            type: 'move',
            children: true,
            hidden: false
          },
          {
            menu: 'Delete task',
            type: 'delete',
            children: false,
            hidden: false
          },
        ]
        if (this.task.parent) {
          this.options = this.options.filter(e => e.type != 'move')
          this.options = this.options.filter(e => e.type != 'add_subtask')
        }
        document.querySelector('.task-menu').classList.add('active')
      }
    }),
      this.$root.$on('disable_menu_click', (value) => {
        this.isDisableClick = value;
      })
  },
  computed: {
    ...mapGetters([
      "getLists",
      "getProjects",
      "getTasks",
      "getPlan",
      "getSubTask",
      "getUserData",
      "getCompletedTasks"
    ]),
    user() {
      return this.$store.state.key.user
    }
  },
  methods: {
    ...mapActions([
      "socket_delete_task",
      "socket_change_order",
      "socket_add_task",
      "addTaskLocal"
    ]),
    ...mapMutations([
      "setMessage"
    ]),
    onTouchMove() {
      if (!this.isMoved) {
        this.isMoved = true;
      }
    },
    onTouchStart() {
      this.isMoved = false;
    },
    closeOrBack() {
      this.type = ''
      if (document.querySelector('.task-menu__content').classList.contains('menu2')) {
        document.querySelector('.task-menu__content').classList.remove('menu2')
        document.querySelector('.task-menu__content').classList.add('menu1')
        document.querySelector('.task-menu__button').innerText = 'Close'
      } else if (document.querySelector('.task-menu__content').classList.contains('menu3')) {
        document.querySelector('.task-menu__content').classList.remove('menu3')
        document.querySelector('.task-menu__content').classList.add('menu2')
      } else if (document.querySelector('.task-menu__content').classList.contains('menu1')) {
        document.querySelector('.task-menu').classList.remove('active')
        this.$root.$emit('task_menu_closed', this.task._id)
      }
    },
    openSubMenu(option) {
      this.type = option.type
      if (option.type == 'priority') {
        document.querySelector('.task-menu__content').classList.remove('menu1')
        document.querySelector('.task-menu__content').classList.add('menu2')
        this.options2 = [
          {
            menu: 'Normal',
            type: 'Normal',
            children: false,
            hidden: false
          },
          {
            menu: 'Medium',
            type: 'Medium',
            children: false,
            hidden: false
          },
          {
            menu: 'High',
            type: 'High',
            children: false,
            hidden: false
          }
        ]
      }
      if (option.type == 'move') {
        document.querySelector('.task-menu__content').classList.remove('menu1')
        document.querySelector('.task-menu__content').classList.add('menu2')
        this.options2 = [
          {
            menu: 'Section',
            type: 'section',
            children: true,
            hidden: false
          },
          {
            menu: 'Lists',
            type: 'lists',
            children: true,
            hidden: false
          }
        ]
      }
      if (option.type == 'section') {
        document.querySelector('.task-menu__content').classList.remove('menu2')
        document.querySelector('.task-menu__content').classList.add('menu3')
        this.options3 = [
          {
            menu: 'No section',
            type: 'no_section',
            children: false,
            hidden: false
          }
        ]
        let list = this.getProjects.find(list => list._id == this.$route.params.project_id)
        if (list) {
          list.sections.forEach(section => {
            if (section._id != this.task.section) {
              this.options3.push({
                menu: section.name,
                type: section._id,
                children: false,
                hidden: false
              })
            }
          })
        }
      }
      if (option.type == 'lists') {
        this.options3 = []
        document.querySelector('.task-menu__content').classList.remove('menu2')
        document.querySelector('.task-menu__content').classList.add('menu3')
        let lists = this.getLists['list'] ? this.getLists['list'] : []
        let folders = this.getLists['folder'] ? this.getLists['folder'] : []
        lists.forEach(list => {
          if (list._id != this.task.project_id) {
            this.options3.push({
              menu: list.name,
              type: list._id,
              children: false,
              hidden: false
            })
          }
        })

        folders.forEach(folder => {
          if (folder.lists) {
            folder.lists.forEach(list => {
              if (list._id != this.task.project_id) {
                this.options3.push({
                  menu: list.name,
                  type: list._id,
                  children: false,
                  hidden: false
                })
              }
            })
          }
        })
      }
    },
    addSubtask() {
      let subtask_id = this.$w_decrypt(this.user._id)
      let subtasks = this.$store.state.tasks.tasks[this.task.project_id].filter(e => e.parent == this.task._id)
      let isAddTask = this.addTaskLocal({
        _id: subtask_id,
        name: '',
        project_id: this.task.project_id,
        order: subtasks.length,
        parent: this.task._id,
        date: new Date(),
        done: false,
        auth: this.user._id,
        assign: { user_id: false, date: '' },
        new: true,
        users: [
          {
            email: this.user.email,
            name: this.user.name,
            unread_message: 0,
            _id: this.user._id,
          }
        ]
      })
      if (isAddTask) {
        this.$root.$emit('openSubtask', this.task._id)
        setTimeout(() => this.$root.$emit('task_rename', subtask_id), 500)
      }
    },
    moveSection(section_id) {

      let uncompleted_tasks = []
      let new_order = []

      if (this.getTasks[this.$route.params.project_id]) {
        this.getTasks[this.$route.params.project_id].forEach((e) => {
          if (e) {
            if (e.section == section_id) {
              e.order = e.order + 1
              uncompleted_tasks.push(e)
            }
          }
        })
      }

      uncompleted_tasks.unshift({
        _id: this.task._id,
        order: 0
      })

      uncompleted_tasks.sort(function (a, b) {
        return a.order - b.order
      })

      uncompleted_tasks.forEach((e, i) => {
        if (e) {
          new_order.push({
            task_id: e._id,
            order: i,
            section_id: section_id
          })
        }
      })

      let new_order_position = {
        out: true,
        new_order: new_order,
        project_id: this.$route.params.project_id,
        section_id: section_id
      }

      this.socket_change_order({ new_order_position, add_global: true, working_out: true })

    },
    moveNoSection() {

      let uncompleted_tasks = []

      if (this.getTasks[this.$route.params.project_id]) {
        this.getTasks[this.$route.params.project_id].forEach((e) => {
          if (e) {
            if (!e.parent && !e.section) {
              e.order = e.order + 1
              uncompleted_tasks.push(e)
            }
          }
        })
      }

      uncompleted_tasks.unshift({
        _id: this.task._id,
        order: 0
      })

      uncompleted_tasks.sort(function (a, b) {
        return a.order - b.order
      })

      let new_order = []

      uncompleted_tasks.forEach((e, i) => {
        if (e) {
          new_order.push({
            task_id: e._id,
            order: i
          })
        }
      })

      let new_order_position = {
        out: true,
        new_order: new_order,
        project_id: this.$route.params.project_id
      }

      this.socket_change_order({ new_order_position, add_global: true, working_out: true })

    },
    moveList(list_id) {

      let a = false

      this.$w_plan(this, this.getPlan, 'task', () => {
        this.setMessage({
          key_message: "warning",
          message: "You can't add more tasks to this list. ",
          plan: true
        })
        a = true
      }, list_id)

      if (a) return

      const tasks = []

      this.getTasks[list_id].forEach((e) => {
        if (e) {
          if (!e.parent && !e.section) {
            tasks.push(e)
          }
        }
      })

      tasks.sort(function (a, b) {
        return a.order - b.order
      })

      let subtasks = this.getSubTask.filter(e => e.parent == this.task._id)
      let array_subtask = []

      subtasks.forEach(e => {
        array_subtask.push(e._id)
      })

      let data = {
        remove_project_id: this.$route.params.project_id,
        add_project_id: list_id,
        subtasks: array_subtask,
        task_id: this.task._id,
        order: tasks.length == 0 ? 0 : tasks.length + 1
      }

      this.$socket.emit('IN_TaskMove', data)

    },
    menuClick(option) {
      if (this.isDisableClick || this.isMoved) return;
      if (option.children) {
        document.querySelector('.task-menu__button').innerText = 'Back'
        this.openSubMenu(option)
      } else {
        if (this.type == 'priority') {
          this.$socket.emit('IN_Priority', {
            task_id: this.task._id,
            project_id: this.$route.params.project_id,
            priority: option.type,
            author: JSON.stringify({ name: this.user.name, id: this.user._id })
          })
        }
        if (option.type == 'copy_link') {
          if (this.getUserData.shortcut_inbox == this.$route.params.project_id) {
            this.$clipboard(process.env.VUE_APP_DOMAIN_NAME + '/inbox/' + this.$route.params.project_id + '/task/' + this.task._id)
          } else {
            this.$clipboard(process.env.VUE_APP_DOMAIN_NAME + '/project/' + this.$route.params.project_id + '/task/' + this.task._id)
          }
        }
        if (option.type == 'rename') {
          this.$root.$emit('task_rename', this.task._id)
        }
        if (option.type == 'delete') {
          this.$w_alert('Do you want to delete this section?', [
            {
              buttonText: 'Cancel',
              callback: (close) => {
                close()
              }
            },
            {
              buttonText: 'Delete',
              className: 'red',
              callback: (close) => {
                this.socket_delete_task({
                  project_id: this.$route.params.project_id,
                  task_id: this.task._id
                })
                close()
              }
            }
          ])
        }
        if (option.type == 'add_subtask') {
          this.addSubtask()
        }
        if (this.type == 'section') {
          if (option.type == 'no_section') {
            this.moveNoSection()
          } else {
            this.moveSection(option.type)
          }
        }
        if (this.type == 'lists') {
          this.moveList(option.type)
        }
        this.type = ''
        document.querySelector('.task-menu__content').classList.remove('menu3')
        document.querySelector('.task-menu__content').classList.remove('menu2')
        document.querySelector('.task-menu__content').classList.add('menu1')
        document.querySelector('.task-menu').classList.remove('active')
        this.$root.$emit('task_menu_closed', this.task._id)
      }
    }
  }
}

</script>

<style lang="scss">
.task-menu {
  position: fixed;
  top: 100%;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  transition: background-color ease-in-out 0.5s, top 0.25s ease-in-out .5s;
  z-index: 99;
  // display: flex;
  align-items: flex-end;
  display: none;
}

.task-menu.active {
  display: flex;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  transition: top ease-in-out .5s, background-color 0.25s ease-in-out 0.5s;
}

.task-menu_wrapper {
  width: 100%;

  @supports (-webkit-touch-callout: none) {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.task-menu__content {
  display: flex;
  align-items: flex-end;
  width: 300vw;
  transition: all ease-in-out .5s;

  &.menu1 {
    transform: translateX(0);
  }

  &.menu2 {
    transform: translateX(-100vw);
  }

  &.menu3 {
    transform: translateX(-200vw);
  }
}

.task-menu__content ul {
  padding: 14px;
  margin: 0;
  width: 100%;
  list-style: none;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 15px;
  max-height: 80vh;
  overflow: auto;
}

.task-menu__content li {

  font-weight: 400;
  color: #464646;
  width: 100%;
  height: 48px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 25px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

.task-menu__button {
  font-weight: 400;
  color: #464646;
  width: calc(100% - 28px);
  height: 48px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 14px;
  margin-top: 0;
  border: 0;
  outline: none;
}
</style>