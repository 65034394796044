<template>
  <section class="navigation-section">
    <div class="container-fluid p-0">
      <div class="navigation">

        <div class="navigation__center">
          <ul class="create_lists" v-if="open_create_lists">
            <li @click.prevent="addFolder" v-if="button_active != 'chat'">
              <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.98 2.72H8.6411L6.21945 0.299203C6.12511 0.204082 6.01281 0.128649 5.88907 0.0772869C5.76533 0.0259244 5.63262 -0.000345135 5.49865 3.42365e-06H1.02C0.749479 3.42365e-06 0.490038 0.107467 0.298751 0.298754C0.107464 0.490041 0 0.749483 0 1.02V13.3127C0.000449442 13.5691 0.102505 13.8149 0.283812 13.9962C0.465119 14.1775 0.710894 14.2796 0.9673 14.28H16.0557C16.306 14.2798 16.5461 14.1802 16.7232 14.0032C16.9002 13.8261 16.9998 13.586 17 13.3357V3.74C17 3.46948 16.8925 3.21004 16.7012 3.01875C16.51 2.82747 16.2505 2.72 15.98 2.72ZM0.68 1.02C0.68 0.92983 0.715821 0.84335 0.779584 0.779587C0.843346 0.715825 0.929826 0.680003 1.02 0.680003H5.49865C5.58856 0.680158 5.67474 0.715915 5.73835 0.779453L7.6789 2.72H0.68V1.02ZM16.32 13.3357C16.3198 13.4057 16.2919 13.4728 16.2423 13.5223C16.1928 13.5719 16.1257 13.5998 16.0557 13.6H0.9673C0.891172 13.5998 0.818227 13.5694 0.764396 13.5156C0.710565 13.4618 0.680224 13.3888 0.68 13.3127V3.4H15.98C16.0702 3.4 16.1567 3.43582 16.2204 3.49959C16.2842 3.56335 16.32 3.64983 16.32 3.74V13.3357Z" fill="#0077FF"/>
              </svg>
              Folder
            </li>
            <li @click.prevent="showAddProjectWindow('chat')">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.1 0H0.900016C0.661322 0 0.432403 0.103066 0.263621 0.286526C0.0948383 0.469985 1.72099e-05 0.71881 1.72099e-05 0.978261V14.0217C-0.00104873 14.2083 0.0474222 14.3912 0.139614 14.5485C0.231806 14.7058 0.363803 14.8309 0.519767 14.9087C0.638998 14.9685 0.7687 14.9997 0.900016 15C1.11134 14.9998 1.31574 14.918 1.47677 14.7693L1.48127 14.7644L3.89176 12.4679C3.94651 12.4178 4.01594 12.3906 4.08751 12.3913H14.1C14.3387 12.3913 14.5676 12.2882 14.7364 12.1048C14.9052 11.9213 15 11.6725 15 11.413V0.978261C15 0.71881 14.9052 0.469985 14.7364 0.286526C14.5676 0.103066 14.3387 0 14.1 0ZM14.4 11.413C14.4 11.4995 14.3684 11.5825 14.3121 11.6436C14.2559 11.7048 14.1796 11.7391 14.1 11.7391H4.08751C3.87347 11.7398 3.66653 11.8227 3.50326 11.9731L1.09127 14.272C1.04746 14.3114 0.994259 14.3365 0.937857 14.3443C0.881456 14.3521 0.824177 14.3423 0.772695 14.316C0.721214 14.2898 0.677649 14.2482 0.647077 14.1961C0.616505 14.144 0.600184 14.0835 0.600017 14.0217V0.978261C0.600017 0.891777 0.631624 0.808836 0.687884 0.747683C0.744145 0.68653 0.820451 0.652174 0.900016 0.652174H14.1C14.1796 0.652174 14.2559 0.68653 14.3121 0.747683C14.3684 0.808836 14.4 0.891777 14.4 0.978261V11.413Z" fill="#0077FF"/>
              </svg>
              Chat
            </li>
            <li @click.prevent="showAddProjectWindow('list')">
              <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 5.54348C15 5.62996 14.9656 5.7129 14.9045 5.77406C14.8433 5.83521 14.7604 5.86957 14.6739 5.86957H0.326087C0.239603 5.86957 0.156662 5.83521 0.0955087 5.77406C0.0343556 5.7129 0 5.62996 0 5.54348C0 5.457 0.0343556 5.37405 0.0955087 5.3129C0.156662 5.25175 0.239603 5.21739 0.326087 5.21739H14.6739C14.7604 5.21739 14.8433 5.25175 14.9045 5.3129C14.9656 5.37405 15 5.457 15 5.54348ZM0.326087 0.652174H14.6739C14.7604 0.652174 14.8433 0.617819 14.9045 0.556666C14.9656 0.495512 15 0.412571 15 0.326087C15 0.239603 14.9656 0.156662 14.9045 0.0955087C14.8433 0.0343556 14.7604 0 14.6739 0H0.326087C0.239603 0 0.156662 0.0343556 0.0955087 0.0955087C0.0343556 0.156662 0 0.239603 0 0.326087C0 0.412571 0.0343556 0.495512 0.0955087 0.556666C0.156662 0.617819 0.239603 0.652174 0.326087 0.652174ZM14.6739 10.4348H0.326087C0.239603 10.4348 0.156662 10.4691 0.0955087 10.5303C0.0343556 10.5914 0 10.6744 0 10.7609C0 10.8474 0.0343556 10.9303 0.0955087 10.9914C0.156662 11.0526 0.239603 11.087 0.326087 11.087H14.6739C14.7604 11.087 14.8433 11.0526 14.9045 10.9914C14.9656 10.9303 15 10.8474 15 10.7609C15 10.6744 14.9656 10.5914 14.9045 10.5303C14.8433 10.4691 14.7604 10.4348 14.6739 10.4348Z" fill="#0077FF"/>
              </svg>
              List
            </li>
          </ul>
          <button @click="open_create_lists = !open_create_lists">
            <img :src="require('@/assets/static/images/plus.svg')" alt="">
          </button>
        </div>

        <div class="navigation__top">
          <button id="bot-nav-list" class="navigation__top-item" :class="button_active == 'list' ? 'active' : ''" @click="clickButtonTab('list')">
            Lists
            <span class="unread_number" v-if="unread_lists > 0"></span>
          </button>
          <button id="bot-nav-chats" class="navigation__top-item" :class="button_active == 'chat' ? 'active' : ''" @click="clickButtonTab('chat')">
            Chats
            <span class="unread_number" v-if="unread_chats > 0"></span>
          </button>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

  import { mapGetters, mapMutations, mapActions } from "vuex";

  export default {
    data: () => ({
      button_active: 'list',
      open_create_lists: false
    }),
    computed: {
      ...mapGetters([
        "getUserData",
        "getUserUsedFileSize",
        "getUserAllFileSize",
        "getShowSmalActivityLog",
        "getChats",
        "getTasks",
        "getProjects",
        "getChangeHistory",
        "get_unreaded_messages_in_project",
        "get_flag_for_unreaded",
        "getRender",
        "getPlan",
        "getLists"
      ]),
      user() {
        return this.$store.state.key.user
      },
      lists() {
        return this.$store.state.projects.projects
      },
      chats() {
        return this.$store.state.chats.chats
      },
      unread_lists() {
        let num = 0
        this.lists.forEach(list => {
          if (this.$store.state.comments.unreaded_messages_in_project[list._id]) {
            num = num + Number(this.$store.state.comments.unreaded_messages_in_project[list._id])
          }
        })
        return num
      },
      unread_chats() {
        let num = 0
        this.chats.forEach(chat => {
          num = num + Number(chat.users.find(e => e.user_id == this.user._id).unread_number)
        })
        return num
      }
    },
    methods: {
      ...mapActions([
        "add_folder_render_lists",
      ]),
      ...mapMutations([
        "setChangeUserData",
        "setMessage"
      ]),
      clickButtonTab(tab) {
        this.button_active = tab
        this.$emit("changeTab", tab)
      },
      showAddProjectWindow(e) {
        this.$root.$emit("openEditLists", e);
      },
      addFolder() {
        let w_bool = false
        this.open_create_lists = false

        this.$w_plan(this, this.getPlan, 'folder', () => {
          this.setMessage({
            key_message: "warning",
            message: "You can't add more folders. ",
            plan: true
          })
          w_bool = true
        })

        if (w_bool) {
          return
        }

        this.setChangeUserData({
          type: 'add_folder',
          field: {
            _id: this.$w_decrypt(this.getUserData._id),
            name: '',
            lists: [],
            order: this.getLists['folder'].length > 0 ? this.getLists['folder'].length : 0,
            rename: true,
            new: true
          }
        })

        setTimeout(() => {
          this.add_folder_render_lists()
        }, 100)

        setTimeout(() => {
          document.querySelector('.folder__flex input').focus()
        }, 500)

      }
    }
  }

</script>