<template>
  <router-link :to="shortcut.type == 'inbox' ? '/inbox/' + user.shortcut_inbox : '/shortcuts/' + shortcut.type" >
    <div class="item-left">
      <div class="item-left-block" v-html="icons[shortcut.type]"></div>
      <div class="item-left-proj">
        <h4>{{ shortcut.type[0].toUpperCase() }}{{ shortcut.type.replaceAll('_', ' ').replace(shortcut.type[0], '') }}</h4>
        <!-- <h6>{{ tasks.length == 0 ? 'No tasks' : tasks.length == 1 ? '1 task' : tasks.length + ' tasks' }}</h6> -->
      </div>
    </div>
  </router-link>
</template>

<script>

  export default {
    props: ['shortcut', 'user'],
    computed: {
      icons() {
        return {
          high_priority: `
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_38_4422)">
                <path d="M14.1343 0.404297H2.86574C1.50659 0.404297 0.404785 1.5061 0.404785 2.86525V14.1338C0.404785 15.493 1.50659 16.5948 2.86574 16.5948H14.1343C15.4935 16.5948 16.5953 15.493 16.5953 14.1338V2.86525C16.5953 1.5061 15.4935 0.404297 14.1343 0.404297Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M8.50173 3.40039H8.49781C8.18593 3.40039 7.93311 3.77445 7.93311 4.23589V9.3649C7.93311 9.82633 8.18593 10.2004 8.49781 10.2004H8.50173C8.81361 10.2004 9.06644 9.82633 9.06644 9.3649V4.23589C9.06644 3.77445 8.81361 3.40039 8.50173 3.40039Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M8.49977 13.6001C8.81273 13.6001 9.06644 13.3464 9.06644 13.0335C9.06644 12.7205 8.81273 12.4668 8.49977 12.4668C8.18681 12.4668 7.93311 12.7205 7.93311 13.0335C7.93311 13.3464 8.18681 13.6001 8.49977 13.6001Z" stroke="#0077FF" stroke-miterlimit="10"/>
              </g>
              <defs>
                <clipPath id="clip0_38_4422">
                  <rect width="17" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
          medium_priority: `
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_38_4451)">
                <path d="M14.1343 0.404297H2.86574C1.50659 0.404297 0.404785 1.5061 0.404785 2.86525V14.1338C0.404785 15.493 1.50659 16.5948 2.86574 16.5948H14.1343C15.4935 16.5948 16.5953 15.493 16.5953 14.1338V2.86525C16.5953 1.5061 15.4935 0.404297 14.1343 0.404297Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M3.96657 9.06693C4.27953 9.06693 4.53324 8.81322 4.53324 8.50026C4.53324 8.1873 4.27953 7.93359 3.96657 7.93359C3.65361 7.93359 3.3999 8.1873 3.3999 8.50026C3.3999 8.81322 3.65361 9.06693 3.96657 9.06693Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M8.49977 9.06693C8.81273 9.06693 9.06644 8.81322 9.06644 8.50026C9.06644 8.1873 8.81273 7.93359 8.49977 7.93359C8.18681 7.93359 7.93311 8.1873 7.93311 8.50026C7.93311 8.81322 8.18681 9.06693 8.49977 9.06693Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M13.0335 9.06693C13.3464 9.06693 13.6001 8.81322 13.6001 8.50026C13.6001 8.1873 13.3464 7.93359 13.0335 7.93359C12.7205 7.93359 12.4668 8.1873 12.4668 8.50026C12.4668 8.81322 12.7205 9.06693 13.0335 9.06693Z" stroke="#0077FF" stroke-miterlimit="10"/>
              </g>
              <defs>
                <clipPath id="clip0_38_4451">
                  <rect width="17" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
          assigned_to_me: `
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_38_4446)">
                <path d="M13.4219 0.404297H3.57812C1.82553 0.404297 0.404785 1.82505 0.404785 3.57763V13.4214C0.404785 15.174 1.82553 16.5948 3.57812 16.5948H13.4219C15.1745 16.5948 16.5953 15.174 16.5953 13.4214V3.57763C16.5953 1.82505 15.1745 0.404297 13.4219 0.404297Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M8.50024 13.8755C11.4689 13.8755 13.8755 11.4689 13.8755 8.50024C13.8755 5.53158 11.4689 3.125 8.50024 3.125C5.53158 3.125 3.125 5.53158 3.125 8.50024C3.125 11.4689 5.53158 13.8755 8.50024 13.8755Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M5.76367 7.88477L7.99796 10.1191" stroke="#0077FF" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.99805 10.119L11.2361 6.88086" stroke="#0077FF" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_38_4446">
                  <rect width="17" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
          today: `
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_38_4440)">
                <path d="M5.99023 2.12109H6.84833H10.2483H11.0093" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M12.7824 2.12014H14.1991C14.4996 2.1004 14.8011 2.14034 15.0862 2.23766C15.3712 2.33497 15.6342 2.48774 15.86 2.68715C16.0857 2.88656 16.2698 3.12868 16.4015 3.39955C16.5333 3.67041 16.6101 3.96468 16.6276 4.26538V5.78728H0.404785V4.26538C0.422296 3.96468 0.499142 3.67041 0.630895 3.39955C0.762647 3.12868 0.946701 2.88656 1.17245 2.68715C1.3982 2.48774 1.66118 2.33497 1.94624 2.23766C2.2313 2.14034 2.53279 2.1004 2.83336 2.12014H4.21764" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M16.5954 5.78906V14.451C16.5779 14.7517 16.5011 15.0459 16.3693 15.3168C16.2376 15.5877 16.0535 15.8298 15.8277 16.0292C15.602 16.2286 15.339 16.3814 15.054 16.4787C14.7689 16.576 14.4674 16.6159 14.1668 16.5962H2.80113C2.50057 16.6159 2.19907 16.576 1.91401 16.4787C1.62895 16.3814 1.36597 16.2286 1.14022 16.0292C0.914475 15.8298 0.730421 15.5877 0.598668 15.3168C0.466915 15.0459 0.390069 14.7517 0.372559 14.451V5.78906H16.5954Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M5.99064 1.19835V3.04406C5.9911 3.15679 5.96801 3.26837 5.92285 3.37166C5.87769 3.47494 5.81145 3.56766 5.72839 3.64386C5.64532 3.72007 5.54725 3.77809 5.44047 3.81421C5.33368 3.85032 5.22053 3.86373 5.10826 3.85359C4.99535 3.86494 4.88132 3.85242 4.77356 3.81685C4.6658 3.78128 4.56672 3.72345 4.48275 3.64712C4.39879 3.57079 4.33181 3.47765 4.28616 3.37376C4.24051 3.26987 4.21721 3.15754 4.21778 3.04406V1.19835C4.21721 1.08487 4.24051 0.972543 4.28616 0.868651C4.33181 0.764759 4.39879 0.671624 4.48275 0.59529C4.56672 0.518957 4.6658 0.461129 4.77356 0.42556C4.88132 0.389991 4.99535 0.377475 5.10826 0.388825C5.33788 0.387527 5.55988 0.471136 5.73159 0.623586C5.81506 0.693876 5.88167 0.782016 5.9265 0.881497C5.97134 0.980979 5.99326 1.08926 5.99064 1.19835V1.19835Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M12.7821 1.19758V3.04329C12.761 3.26377 12.6585 3.46848 12.4946 3.61745C12.3307 3.76642 12.1172 3.84896 11.8957 3.84896C11.6742 3.84896 11.4607 3.76642 11.2968 3.61745C11.1329 3.46848 11.0304 3.26377 11.0093 3.04329V1.19758C11.0088 1.08485 11.0319 0.973269 11.0771 0.869983C11.1222 0.766696 11.1885 0.67398 11.2715 0.597773C11.3546 0.521566 11.4527 0.463547 11.5595 0.427433C11.6662 0.391319 11.7794 0.377905 11.8917 0.388052C12.1238 0.386281 12.3485 0.469824 12.5231 0.622813C12.6048 0.694542 12.6703 0.782959 12.7149 0.882105C12.7596 0.981252 12.7825 1.08882 12.7821 1.19758V1.19758Z" stroke="#0077FF" stroke-miterlimit="10"/>
              </g>
              <defs>
                <clipPath id="clip0_38_4440">
                  <rect width="17" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
          inbox: `
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_38_4434)">
                <path d="M13.4219 0.404297H3.57812C1.82553 0.404297 0.404785 1.82505 0.404785 3.57763V13.4214C0.404785 15.174 1.82553 16.5948 3.57812 16.5948H13.4219C15.1745 16.5948 16.5953 15.174 16.5953 13.4214V3.57763C16.5953 1.82505 15.1745 0.404297 13.4219 0.404297Z" stroke="#0077FF" stroke-miterlimit="10"/>
                <path d="M16.5952 10.8477H10.0381" stroke="#0077FF" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M6.3224 10.8477H0.404785" stroke="#0077FF" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M6.32241 10.8477C6.30561 11.1019 6.3412 11.357 6.42696 11.597C6.51273 11.8369 6.64685 12.0568 6.82101 12.2428C6.99517 12.4289 7.20567 12.5772 7.43948 12.6786C7.67329 12.7799 7.92542 12.8323 8.18026 12.8323C8.43511 12.8323 8.68724 12.7799 8.92105 12.6786C9.15485 12.5772 9.36536 12.4289 9.53952 12.2428C9.71368 12.0568 9.8478 11.8369 9.93356 11.597C10.0193 11.357 10.0549 11.1019 10.0381 10.8477" stroke="#0077FF" stroke-miterlimit="10" stroke-linecap="round"/>
              </g>
              <defs>
                <clipPath id="clip0_38_4434">
                  <rect width="17" height="17" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          `,
        }
      }
    }
  }

</script>