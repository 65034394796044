<template>
  <div id="nav-left" class="nav-left" :class="open ? 'active' : ''">
    <main class="nav-left__main">
    
      <ul id="menu-list" :class="tab == 'list' ? 'active' : ''">
        <Lists></Lists>
      </ul>
    
      <ul id="menu-list" :class="tab == 'chat' ? 'active' : ''">
        <ChatLists></ChatLists>
      </ul>
    
    </main>
    <BottomNavbar @changeTab="changeTab" />
  </div>
</template>
  
<script>

  import BottomNavbar from '@/components/app/BottomNavbar'
  import Lists from '@/components/lists/Lists'

  export default {
    data: () => ({
      tab: 'list'
    }),
    props: {
      open: Boolean
    },
    components: {
      BottomNavbar,
      Lists,
      ChatLists: () => import('@/components/chats/ChatLists.vue')
    },
    methods: {
      changeTab(tab) {
        this.tab = tab
      }
    },

  }

</script>