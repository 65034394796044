<template>
  <div 
    @touchstart="startDownPress" 
    @touchend="upPress" 
    @touchmove="onTouchMove" 
    class="link" 
    :class="$route.params.project_id == list._id ? 'router-link-active' : ''"
  >
    <div class="item-left">
      <div class="item-left-block">
        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 6.28261C17 6.38062 16.9611 6.47462 16.8918 6.54393C16.8224 6.61324 16.7284 6.65217 16.6304 6.65217H0.369565C0.27155 6.65217 0.17755 6.61324 0.108243 6.54393C0.0389363 6.47462 0 6.38062 0 6.28261C0 6.18459 0.0389363 6.09059 0.108243 6.02129C0.17755 5.95198 0.27155 5.91304 0.369565 5.91304H16.6304C16.7284 5.91304 16.8224 5.95198 16.8918 6.02129C16.9611 6.09059 17 6.18459 17 6.28261ZM0.369565 0.73913H16.6304C16.7284 0.73913 16.8224 0.700195 16.8918 0.630888C16.9611 0.561581 17 0.46758 17 0.369565C17 0.27155 16.9611 0.17755 16.8918 0.108243C16.8224 0.0389363 16.7284 0 16.6304 0H0.369565C0.27155 0 0.17755 0.0389363 0.108243 0.108243C0.0389363 0.17755 0 0.27155 0 0.369565C0 0.46758 0.0389363 0.561581 0.108243 0.630888C0.17755 0.700195 0.27155 0.73913 0.369565 0.73913ZM16.6304 11.8261H0.369565C0.27155 11.8261 0.17755 11.865 0.108243 11.9343C0.0389363 12.0036 0 12.0976 0 12.1957C0 12.2937 0.0389363 12.3877 0.108243 12.457C0.17755 12.5263 0.27155 12.5652 0.369565 12.5652H16.6304C16.7284 12.5652 16.8224 12.5263 16.8918 12.457C16.9611 12.3877 17 12.2937 17 12.1957C17 12.0976 16.9611 12.0036 16.8918 11.9343C16.8224 11.865 16.7284 11.8261 16.6304 11.8261Z" fill="#0077FF"/>
        </svg>
      </div>
      <div class="item-left-proj">
        <input v-if="rename_bool" type="text" v-model="list_name" @blur="renameList" @keypress.enter="renameList">
        <h4 v-else>{{ list.name }}</h4>
        <!-- <h6>{{ tasks.length == 0 ? 'No tasks' : tasks.length == 1 ? '1 task' : tasks.length + ' tasks' }}</h6> -->
      </div>
    </div>
    <div class="item-right" v-if="unread > 0">{{ unread }}</div>
  </div>
</template>

<script>

import { mapMutations } from "vuex"

export default {
  props: ['list'],
  data: () => ({
    menu_opened: false,
    list_name: '',
    rename_bool: false,
    pressTime: '',
    isFolderMoved: false,
    isShowMenu: false,
    startTime: 0,
  }),
  computed: {
    tasks() {
      return this.$store.state.tasks.tasks[this.list._id] || []
    },
    unread() {
      return this.$store.state.comments.unreaded_messages_in_project[this.list._id]
    }
  },
  mounted() {
    this.$root.$on('listContextMenu_close', () => {
      this.menu_opened = false
    })
    this.$root.$on('renameList', (list) => {
      if (list._id == this.list._id) {
        this.list_name = this.list.name
        this.rename_bool = true
        setTimeout(() => {
          document.querySelector('.link .item-left-proj input').focus()
        }, 500)
      }
    })
  },
  methods: {
    startDownPress() {
      this.startTime = Date.now();
      this.isShowMenu = true;
      this.pressTime = setTimeout(() => {
        if (this.isShowMenu && !this.isFolderMoved) {
          let position_menu = {
            data: this.list,
            bool: true,
            show: true,
            type: 'list'
          }
          this.$root.$emit('listContextMenu_open', position_menu)
        }
      }, 400)
    },
    upPress() {
      const duration = Date.now() - this.startTime;

      if (!this.isFolderMoved && duration < 400) {
        if (this.$route.params.project_id != this.list._id) {
          this.$router.push('/project/' + this.list._id)
        }
      }
      clearTimeout(this.pressTime);
      this.isFolderMoved = false;
    },
    onTouchMove() {
      if (!this.isFolderMoved) {
        this.isFolderMoved = true;
      }
    },
    renameList() {
      if (this.list_name.slice(0, 160)) {
        this.$socket.emit('IN_ProjectRename', {
          _id: this.list._id,
          name: this.list_name.slice(0, 160)
        })
        this.rename_bool = false
      } else {
        document.querySelector('.link .item-left-proj input').focus()
      }
    }
  }
}

</script>