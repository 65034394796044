<template>
  <div style="padding-bottom: 20px;">
    <li style="display: flex; flex-direction: column">
      <Shortcut
        v-for="shortcut of user.shortcuts"
        :key="shortcut.type"
        :shortcut="shortcut"
        :user="user"
        :class="shortcut.type == user.shortcuts[0].type ? shortcut.type + ' border-none' : shortcut.type"
      ></Shortcut>
    </li>
    <li v-for="list of lists" :key="list._id">
      <List :list="list" :class="list._id != lists[0]._id ? '' : user.shortcuts.length == 0 ? 'border-none' : ''"></List>
    </li>
    <li v-for="folder of folders" :key="folder._id">
      <div class="folder" :ref="'folder_' + folder._id">
        <div
          class="folder__flex"
          @touchstart="startDownPress(folder, $event)"
          @touchend="upPress(folder._id)"
          @touchmove="onTouchMove"
        >
          <input
            v-if="folder.rename"
            type="text"
            v-model="folder.name"
            @blur="renameFolder(folder)"
            @keypress.enter="renameFolder(folder)"
          />
          <p v-else>{{ folder.name }}</p>
          <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
              d="M0.576117 0.99729C0.70958 0.997029 0.838921 1.04351 0.941688 1.12867L4.00335 3.68766L7.07071 1.22006C7.12914 1.17261 7.19637 1.13718 7.26854 1.1158C7.3407 1.09442 7.41638 1.08751 7.49122 1.09547C7.56607 1.10343 7.6386 1.12611 7.70465 1.16219C7.77071 1.19828 7.82898 1.24706 7.87611 1.30574C7.92356 1.36417 7.95899 1.4314 7.98037 1.50356C8.00176 1.57573 8.00866 1.65141 8.0007 1.72625C7.99274 1.8011 7.97006 1.87363 7.93398 1.93968C7.89789 2.00573 7.84911 2.064 7.79043 2.11114L4.3632 4.87006C4.261 4.95407 4.13279 5 4.00049 5C3.86818 5 3.73998 4.95407 3.63777 4.87006L0.210546 2.01403C0.152707 1.96608 0.104896 1.90719 0.0698514 1.84073C0.0348067 1.77427 0.0132165 1.70156 0.00631857 1.62674C-0.00057888 1.55193 0.0073514 1.47649 0.0296535 1.40474C0.0519562 1.333 0.0881925 1.26635 0.136289 1.20864C0.189442 1.14311 0.256451 1.09017 0.332499 1.05363C0.408547 1.01709 0.491746 0.997848 0.576117 0.99729Z" 
              fill="#9A9A9A"
            ></path>
          </svg>
        </div>
        <div class="folder__drag-drop" v-if="folder.lists.length == 0">
          Drag & drop lists here
        </div>
        <li v-for="list of folder.lists" :key="list._id">
          <List :list="list" :class="list._id == folder.lists[0]._id ? 'border-none' : ''"></List>
        </li>
      </div>
    </li>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import List from '@/components/lists/List';
import Shortcut from '@/components/lists/Shortcut';

export default {
  data: () => ({
    lists: [],
    folders: [],
    menu_opened: false,
    pressTime: '',
    touchTime: '',
    startTime: 0,
    isShowMenu: false,
    isFolderMoved: false,
  }),
  components: {
    List,
    Shortcut,
  },
  methods: {
    renameFolder(folder) {
      if (folder.new) {
        if (folder.name != '' && folder.name.length < 18) {
          this.$socket.emit('IN_CreateFolder', {
            _id: folder._id,
            name: folder.name,
            order: folder.order
          })
        }
      } else {
        let folder_id = folder._id
        let folder = this.folders.find((e) => e._id == folder_id);
        if (folder.name.slice(0, 160)) {
          this.$socket.emit('IN_RenameFolder', {
            folder_id: folder._id,
            folder_name: folder.name.slice(0, 160),
          });
          let folders = this.folders;
          folders.forEach((e, i) => {
            if (e._id == folder._id) {
              folders[i].rename = false;
              this.folders = [];
              this.folders = folders;
            }
          });
        } else {
          document.querySelector('.folder__flex input').focus();
        }
      }
    },
    openFolder(id) {
      this.$refs['folder_' + id][0].classList.toggle('active');
      if (this.$refs['folder_' + id][0].classList.contains('active')) {
        localStorage.setItem(`folder_${id}`, true)
      } else {
        localStorage.setItem(`folder_${id}`, false)
      }
    },
    startDownPress(folder, $event) {
      this.startTime = Date.now();
      this.isShowMenu = true;
      this.pressTime = setTimeout(() => {
        if (this.isShowMenu && !this.isFolderMoved) {
          let position_menu = {
            data: folder,
            bool: false,
            show: true,
            type: 'folder',
          };
          this.$root.$emit('listContextMenu_open', position_menu);
        }
      }, 500);
    },
    upPress(folder_id) {
      const duration = Date.now() - this.startTime;
      if (!this.isFolderMoved && duration < 500) {
        this.openFolder(folder_id);
      }
      clearTimeout(this.pressTime);
      this.isFolderMoved = false;
    },
    onTouchMove() {
      if (!this.isFolderMoved) {
        this.isFolderMoved = true;
      }
    },
  },
  mounted() {
    this.$root.$on('renameFolder', (data) => {
      let folders = this.folders;
      folders.forEach((e, i) => {
        if (e._id == data._id) {
          folders[i].rename = true;
          this.folders = [];
          this.folders = folders;
          setTimeout(() => {
            document.querySelector('.folder__flex input').focus();
          }, 500);
        }
      });
    });
    this.$root.$on('listContextMenu_close', () => {
      this.menu_opened = false;
    });
    this.folders = this.getLists['folder']
      ? this.getLists['folder']
          .filter((e) => e._id != undefined)
          .sort((a, b) => a.order - b.order)
      : [];
    this.lists = this.getLists['list']
      ? this.getLists['list']
          .filter((e) => e._id != undefined)
          .sort((a, b) => a.order - b.order)
      : [];

    setTimeout(() => {
      this.folders.forEach((e) => {
        if (localStorage.getItem(`folder_${e._id}`) == 'true') {
          this.$refs['folder_' + e._id][0].classList.add('active');
        }
      })
    }, 100)
  },
  watch: {
    get_flag_for_last_chat() {
      this.folders = this.getLists['folder']
        .filter((e) => e._id != undefined)
        .sort((a, b) => a.order - b.order);
      this.lists = this.getLists['list']
        .filter((e) => e._id != undefined)
        .sort((a, b) => a.order - b.order);
    },
  },
  computed: {
    ...mapGetters(['get_flag_for_last_chat', 'getLists']),
    user() {
      return this.$store.state.key.user;
    },
  },
};
</script>
